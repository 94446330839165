<template>
  <b-modal v-model="showModal" :title="modalTitle" centered hide-footer>
    <div>
      <div>
        <InputSelect
          title="Status"
          name="status"
          v-model="data.enable"
          v-bind:options="exportOptions"
          valueField="id"
          textField="name"
          @onDataChange="data.enable = $event"
        >
        </InputSelect>
      </div>

      <div class="tw-flex tw-justify-between tw-mt-6">
        <b-button
          variant="primary"
          id="exportPrice"
          pill
          class="px-4"
          @click="handleExport()"
        >
          Export</b-button
        >
      </div>
    </div></b-modal
  >
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
export default {
  name: "ModalProductExport",
  components: { InputSelect },
  data() {
    return {
      showModal: false,
      data: {
        enable: null,
      },
      exportOptions: [
        {
          id: null,
          name: "ทั้งหมด",
        },
        {
          id: 1,
          name: "เปิดใช้งาน",
        },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
      ],
    };
  },
  props: {
    productType: {
      type: String,
      default: "T",
    },
    importType: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    modalTitle() {
      return `Export - ${this.productType == "P" ? "Package" : "Test"}`;
    },
  },
  methods: {
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.data = {
        enable: null,
      };
    },
    handleExport() {
      const payload = {
        ...this.data,
        enable: this.data.enable
          ? this.data.enable === 1
            ? true
            : false
          : null,
      };
      this.$emit("export", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
