<template>
  <b-modal
    v-model="showModal"
    :title="modalTitle"
    centered
    hide-footer
    @hidden="close"
  >
    <div>
      <div>
        <UploadFileV2
          textFloat="File"
          placeholder="Please Choose File"
          format="excel"
          name="file"
          text="*Please upload only file .xlsx less than 10 MB"
          isRequired
          v-on:onFileChange="onFileChange"
          v-on:delete="deleteFile"
          :fileName="filename"
          v-model="data.file"
          :v="$v.data.file"
          id="uploadfile"
        />
      </div>

      <div class="tw-flex tw-justify-end tw-mt-4">
        <b-button
          variant="primary"
          id="importPrice"
          pill
          class="px-4"
          @click="handleImport()"
        >
          Import</b-button
        >
      </div>
    </div></b-modal
  >
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ModalProductExport",
  components: {
    UploadFileV2,
  },
  data() {
    return {
      showModal: false,
      data: {
        file: null,
      },
      filename: "",
      isDisable: false,
      exportOptions: [
        {
          id: null,
          name: "ทั้งหมด",
        },
        {
          id: 1,
          name: "เปิดใช้งาน",
        },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
      ],
    };
  },
  props: {
    importType: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    modalTitle() {
      return `Import - ${this.importType == 3 ? "ปรับราคา" : "เปิด/ปิด"}`;
    },
  },
  validations() {
    return {
      data: {
        file: { required },
      },
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.deleteFile();
      this.$v.$reset();
    },
    handleImport() {
      this.$v.$touch();
      this.$emit("import", this.data.file);
    },
    onFileChange(file) {
      this.filename = file.name;
      this.isDisable = false;
      this.data.file = file;
    },
    deleteFile() {
      this.data.file = null;
      this.filename = null;
      this.isDisable = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
