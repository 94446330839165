<template>
  <div class="product-setting">
    <b-row>
      <b-col cols="4">
        <p class="tw-font-bold tw-text-xl">นำเข้าข้อมูลจาก LIS</p>
      </b-col>
      <b-col cols="8" class="tw-flex tw-justify-end">
        <b-button
          :disabled="productLogDetail.isProcessing"
          class="btn-import"
          @click="$refs.confirmImportProduct.show()"
        >
          นำเข้าข้อมูล
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="3">
        <div>นำเข้าข้อมูลล่าสุด :</div>
      </b-col>
      <b-col cols="8">
        <div>
          {{
            productLogDetail.start
              ? $moment(productLogDetail.start).format("DD/MM/YYYY hh:mm:ss A")
              : "-"
          }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="3">
        <div>สถานะการนำเข้า :</div>
      </b-col>
      <b-col cols="8">
        <div>
          <span v-if="productLogDetail.isProcessing">กำลังดำเนินการ</span>
          <span v-else
            >นำเข้าสำเร็จ
            {{
              productLogDetail.start
                ? $moment(productLogDetail.end).format("DD/MM/YYYY hh:mm:ss A")
                : ""
            }}</span
          >
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="4">
        <p class="tw-font-bold tw-text-xl">Package / Test Setting</p>
      </b-col>
      <b-col cols="8">
        <div class="tw-flex tw-w-full">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.search"
                :placeholder="filterPlaceholder"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="getProductList"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                  @click="getProductList"
                ></b-icon>
              </b-button>
            </div>
          </div>
          <b-dropdown text="Action" right class="ml-2">
            <b-dropdown-item
              v-for="(item, index) in actionOptions"
              :key="index"
              @click="handleAction(item)"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-tabs
      content-class="mt-3"
      active-nav-item-class="font-weight-bold "
      justified
    >
      <template v-for="item in productTypes">
        <b-tab
          :title="item.name"
          :key="item.productType"
          @click="onChangeProductType(item.productType)"
        >
          <ProductTable
            :items="items"
            :fields="fields"
            :isBusy="isBusy"
            :rows="rows"
            :filter="filter"
            :pageOptions="pageOptions"
            @filterPage="filterPage"
        /></b-tab>
      </template>
    </b-tabs>

    <ModalProductExport
      id="exportModal"
      ref="exportModal"
      :product-type="filter.productType"
      @export="onExport"
    />

    <ModalProductImport
      id="importModal"
      ref="importModal"
      :import-type="importType"
      @import="onImport"
    />
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
    <ModalConfirm
      id="confirmImportProduct"
      ref="confirmImportProduct"
      text="ยืนยันการนำเข้าข้อมูลจาก LIS ?"
      @handler="importProductFromLIS"
    />
  </div>
</template>

<script>
import ProductTable from "./component/ProductTable";
import ModalProductExport from "./component/ModalProductExport.vue";
import ModalProductImport from "./component/ModalProductImport.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import ModalConfirm from "@/components/modal/ModalConfirm";
export default {
  components: {
    ProductTable,
    ModalProductExport,
    ModalAlertText,
    ModalProductImport,
    ModalConfirm,
  },
  data() {
    return {
      tabIndex: 0,
      productTypes: [],
      branchList: "",

      filter: {
        search: "",
        productType: null,
        skip: 1,
        take: 10,
        page: 1,
      },
      // state css
      iconSearch: false,

      // state modal
      textModal: "",
      arrStatus: [],

      // state table
      items: [],

      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // option export
      actionOptions: [
        {
          id: 1,
          name: "Export ปรับราคา",
        },
        {
          id: 2,
          name: "Export เปิด/ปิด",
        },
        {
          id: 3,
          name: "Import ปรับราคา",
        },
        {
          id: 4,
          name: "Import เปิด/ปิด",
        },
      ],
      importType: 1,

      productLogDetail: {
        isProcessing: false,
        isSucceed: false,
        start: "",
        end: "",
      },
      interval: null,
    };
  },
  async created() {
    this.getProductLog();
    await this.getProductTypeList();
    await this.getProductList();
    this.getBranchList();

    this.interval = setInterval(() => {
      this.getProductLog();
    }, 300000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    filterPlaceholder() {
      return this.filter.productType === "P"
        ? "Name, Code"
        : "Name, Code, SpecimenName, ContainerName";
    },
    fields() {
      const columns =
        this.filter.productType === "P"
          ? [
              {
                key: "profileName",
                label: "Name",
                class: "",
              },
              {
                key: "profileCode",
                label: "Code",
                class: "",
              },
              {
                key: "profileDescription",
                label: "Description",
                class: "",
              },
              {
                key: "branchCount",
                label: "Client Count",
                class: "",
              },
              { key: "actions", label: "Action" },
            ]
          : [
              {
                key: "testName",
                label: "Name",
                class: "",
              },
              {
                key: "testCode",
                label: "Code",
                class: "",
              },
              {
                key: "testCaption",
                label: "Description",
                class: "",
              },
              {
                key: "specimenName",
                label: "SpecimenName",
                class: "",
              },
              {
                key: "containerName",
                label: "ContainerName",
                class: "",
              },
              {
                key: "branchCount",
                label: "Client Count",
                class: "",
              },
              { key: "actions", label: "Action" },
            ];
      return columns;
    },
  },
  methods: {
    async importProductFromLIS(value) {
      if (!value) return;
      this.$EventBus.$emit("showLoading");
      const data = await this.$services.productsetting.importProductFromLIS();

      if (data.result == 1 && data.detail) {
        this.openModalAlertText("Import file สำเร็จ");
        this.getProductLog();
      } else {
        this.openModalAlertText("ระบบผิดพลาด กรุณาลองใหม่อีกครั้ง");
        this.$EventBus.$emit("hideLoading");
      }
    },
    async getProductLog() {
      const data = await this.$services.productsetting.getProductLog();

      if (data.result == 1 && data.detail) {
        this.productLogDetail = data.detail.detail;
      }
      this.$EventBus.$emit("hideLoading");
    },
    filterPage(filter) {
      let { page } = filter;

      this.filter.skip = page;
      this.searchAdminRole();
    },
    onChangeProductType(type) {
      this.filter.productType = type;
      this.getProductList();
    },
    async getBranchList() {
      const data = await this.$services.productsetting.getAllProductBranch();

      if (data.result == 1 && data.detail) {
        this.branchList = data.detail;
      }
    },
    async getProductTypeList() {
      this.isBusy = true;
      const data = await this.$services.productsetting.getProductTypeList();

      if (data.result == 1 && data.detail) {
        this.productTypes = data.detail;
        this.filter.productType = data.detail[0].productType;
      }

      this.isBusy = false;
    },
    async getProductList() {
      this.isBusy = true;
      const data = await this.$services.productsetting.getProductList(
        this.filter
      );

      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.count;
      }

      this.isBusy = false;
    },
    handleAction(item) {
      this.importType = item.id;
      switch (item.id) {
        case 1:
          this.$refs.exportModal.show();
          break;
        case 2:
          this.onExport({
            enable: null,
          });
          break;
        default:
          this.$refs.importModal.show();
          break;
      }
    },
    async onExport(data) {
      let payload = {
        productType: this.filter.productType,
        branchFilter: this.branchList,
        search: this.filter.search,
        enable: data.enable,
      };
      const url =
        this.importType == 1
          ? `ecom/Backoffice/Export/ProductPrice/${this.filter.productType}`
          : `ecom/Backoffice/Export/ProductEnable/${this.filter.productType}`;
      const fileTitle = this.filter.productType == "P" ? "Package_" : "Test_";
      const fileSubTitle =
        this.importType == 1 ? "PoductPrice" : "PoductEnable";
      this.$EventBus.$emit("showLoading");
      const respone = await this.$services.productsetting.getExportFile(
        url,
        payload
      );

      this.$services.utils.resolveAndDownloadBlob(
        respone,
        fileTitle + fileSubTitle
      );
      this.$EventBus.$emit("hideLoading");
      this.$refs.exportModal.close();
    },
    async onImport(file) {
      try {
        const url =
          this.importType == 3
            ? `ecom/Backoffice/Import/ProductPrice`
            : `ecom/Backoffice/Import/ProductEnable`;

        this.$EventBus.$emit("showLoading");
        const respone = await this.$services.productsetting.saveImportFile(
          url,
          file
        );
        const fileTitle = this.filter.productType == "P" ? "Package_" : "Test_";
        const fileSubTitle =
          this.importType == 3 ? "PoductPrice" : "PoductEnable";
        this.$services.utils.resolveAndDownloadBlob(
          respone,
          fileTitle + fileSubTitle
        );
        this.openModalAlertText("Import file สำเร็จ");
      } catch {
        this.openModalAlertText(
          "ระบบผิดพลาด กรุณาลองใหม่อีกครั้ง <br /> format file ไม่ถูกต้อง"
        );
      } finally {
        this.$EventBus.$emit("hideLoading");
        this.$refs.importModal.close();
      }
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.product-setting {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }

  :deep(.text-info) {
    color: var(--font-main-color) !important;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
  }

  // ! override
  .g-form {
    width: 100%;
  }

  .btn-filter {
    width: 100%;
    max-width: 150px;

    &.cancel {
      border: none;
      background: #606060;
      color: white !important;
    }

    &.save {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--secondary-color);
      border: none;
      color: white !important;
    }
    &.save:hover {
      background: var(--secondary-color);
      cursor: pointer;
    }
    &.cancel:hover {
      background: #606060;
      color: white !important;

      cursor: pointer;
    }
  }
}
</style>
